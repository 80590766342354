import React from 'react';
import { AppBar, Toolbar, useTheme } from '@mui/material';

import { MobileBottomNavDrawer } from './MobileBottomNavDrawer';
import { useNavConstants } from '../../../custom-hooks/useNavConstants';
import { CustomNavItem } from './CustomNavItem';

export const MobileBottomNavBar = ({
  openMobileBottomNavDrawer,
  toggleMobileBottomNavDrawer,
}: {
  openMobileBottomNavDrawer: boolean;
  toggleMobileBottomNavDrawer: (newOpen: boolean) => void;
}) => {
  const { topNavItems } = useNavConstants();
  const { palette } = useTheme();

  return (
    <>
      <AppBar
        data-testid="mobile-bottom-navbar"
        position="fixed"
        sx={{
          top: 'auto',
          bottom: 0,
          boxShadow: 'none',
          minHeight: '56px',
          bgcolor: palette.keyops.background.default,
        }}
      >
        <Toolbar
          data-testid="mobile-bottom-navbar"
          sx={{
            m: 0,
            py: 0,
            px: 0,
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignContent: 'center',
            borderTop: `1px solid ${palette.keyops.blue.lightSteelBlue20}`,
          }}
        >
          {topNavItems.map((navItem) => (
            <CustomNavItem
              key={navItem.name}
              item={navItem}
              isMobileBottomNavbar={true}
            />
          ))}
        </Toolbar>
      </AppBar>

      {/* Displayed on click on MobileTopNavBar > More icon  */}
      <MobileBottomNavDrawer
        openMobileBottomNavDrawer={openMobileBottomNavDrawer}
        toggleMobileBottomNavDrawer={toggleMobileBottomNavDrawer}
      />
    </>
  );
};
