// External Dependencies
import React, { useMemo, useState } from 'react';
import {
  useTheme,
  useMediaQuery,
  Stack,
  IconButton,
  Tooltip,
} from '@mui/material/';
import { useTranslation } from 'react-i18next';
import { EngagementCard } from './EngagementCard';
import useReferralShareOptions from '../../../../../custom-hooks/useReferralShareOptions';
import Loading from '../../../../../components/Loading';
import { FilledSend, Mail, Link, Share } from '@keyops-hcp/ui-components';

type ReferralOpportunitiesCardProperties = {
  engagementId: number;
  title: string;
  payout: number;
  points?: number;
};

const ReferralOpportunitiesCard = ({
  engagementId,
  title,
  payout,
  points,
}: ReferralOpportunitiesCardProperties) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const { isLoading, isError, handleCopyClick, mailToClick, mobileShare } =
    useReferralShareOptions(engagementId);

  const [copyReferralLinkTooltip, setCopyReferralLinkTooltip] = useState(
    t('engagement.copyReferralLinkTooltip'),
  );

  const handleCopyReferralLink = () => {
    setCopyReferralLinkTooltip(t('engagement.copyReferralLinkTooltipCopied'));
    handleCopyClick();
  };

  const handleCopyReferralLinkClose = () => {
    setCopyReferralLinkTooltip(t('engagement.copyReferralLinkTooltip'));
  };

  const getAdditionalActions = () => {
    if (isMobile) {
      return <></>;
    }

    if (isLoading) {
      return <Loading />;
    }

    return (
      <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
        <Tooltip
          title={copyReferralLinkTooltip}
          onClose={handleCopyReferralLinkClose}
        >
          <IconButton
            data-testid="copy-link-btn"
            onClick={handleCopyReferralLink}
            disabled={isError}
          >
            <Link
              sx={{
                fontSize: '28px',
                color: theme.palette.keyops.blue.keyopsBlue,
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('engagement.emailReferralLink')}>
          <IconButton
            data-testid="mail-btn"
            onClick={mailToClick}
            disabled={isError}
          >
            <Mail
              sx={{
                fontSize: '32px',
                color: theme.palette.keyops.blue.keyopsBlue,
              }}
            />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  const additionalActions = useMemo(
    () => getAdditionalActions(),
    [isMobile, isLoading, isError, theme, copyReferralLinkTooltip],
  );

  return (
    <>
      <EngagementCard
        action={() => isMobile && mobileShare()}
        actionLabel={isMobile && t('general.share')}
        honorarium={payout}
        title={title}
        icon={<FilledSend />}
        iconBgColor={theme.palette.keyops.blue.midnightBlue}
        type="referral"
        points={points}
        accentIcon={isMobile && <Share />}
        additionalActions={additionalActions}
      />
    </>
  );
};

export default ReferralOpportunitiesCard;
