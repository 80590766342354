import React, { useCallback, useRef, useState } from 'react';
import { Box, Container, useTheme } from '@mui/material';
import { Outlet } from 'react-router';

import { CustomTopAppBar } from './components/CustomTopAppBar';
import { CustomSideNavBar } from './components/CustomSideNavBar';

export const AppLayout = ({ topNavBarHeight = 60 }): JSX.Element => {
  const { palette } = useTheme();
  // Handling display of side navbar items in mobile
  const [openMobileBottomNavDrawer, setOpenMobileBottomNavDrawer] =
    useState(false);
  const toggleMobileBottomNavDrawer = useCallback(
    (newOpen: boolean) => setOpenMobileBottomNavDrawer(newOpen),
    [],
  );

  // Desktop top navbar handling: on scroll, display a border bottom
  const [scrolled, setScrolled] = useState(false);
  const scrollDemoRef = useRef(null);

  const handleScroll = () => {
    if (scrollDemoRef.current) {
      const { scrollTop } = scrollDemoRef.current;
      if (scrollTop > topNavBarHeight) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }
  };

  return (
    <Container
      id="app-layout-container"
      data-testid="app-layout-container"
      maxWidth={false} // Makes the container span the full width
      disableGutters // Removes extra padding from the container edges
      sx={{
        display: 'flex',
        height: '100vh',
        overflow: 'hidden',
        backgroundColor: palette.keyops.background.default,
      }} // Maintain flexbox layout
    >
      {/* Top Navbar */}
      <CustomTopAppBar
        toggleMobileBottomNavDrawer={toggleMobileBottomNavDrawer}
        topNavBarHeight={topNavBarHeight}
        scrolled={scrolled}
      />

      {/* Left Sidebar */}
      <CustomSideNavBar
        openMobileBottomNavDrawer={openMobileBottomNavDrawer}
        toggleMobileBottomNavDrawer={toggleMobileBottomNavDrawer}
      />

      {/* Main Content */}
      <Box
        component="main"
        data-testid="main-content"
        minHeight={`calc(100vh - ${topNavBarHeight * 2}px)`} // Account for top and bottom navbars
        flexGrow={1}
        // The scroll should be allowed on this component only
        overflow={'auto'}
        mt={topNavBarHeight + 'px'} // Push content below navbar
        sx={{
          backgroundColor: 'transparent',
          transition: 'margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        }}
        ref={scrollDemoRef}
        onScroll={handleScroll}
      >
        <Outlet />
      </Box>
    </Container>
  );
};
