import React from 'react';
import { Stack, Toolbar, useTheme } from '@mui/material';

import { Notifications } from '@keyops-hcp/ui-components';

import { ProfileNavSection } from './ProfileNavSection';
import { EarningsNavSection } from './EarningsNavSection';
import { RewardsNavSection } from './RewardsNavSection';

export const DesktopTopAppBar = () => {
  const { palette } = useTheme();
  return (
    <Toolbar
      data-testid="desktop-top-navbar"
      disableGutters
      sx={{
        justifyContent: 'flex-end',
        backgroundColor: palette.keyops.background.default,
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        spacing={2}
        px={0}
      >
        <EarningsNavSection />
        <RewardsNavSection />
        <Notifications />
        <ProfileNavSection />
      </Stack>
    </Toolbar>
  );
};
