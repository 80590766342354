import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Calendar,
  ClockCircle,
  EngagementEarningsDisplay,
  hoverCardStyle,
  InfoOutline,
  KeyopsHeader3,
  KeyopsSubTitle1,
  PrimaryTooltip,
  RightArrow,
} from '@keyops-hcp/ui-components';

import { EngagementCardFooter } from './EngagementCardFooter';
import { ModeratorBadge } from './ModeratorBadge';

export const EngagementCardTypes = ['survey', 'adBoard', 'referral'] as const;
export type EngagementCardType = (typeof EngagementCardTypes)[number];

export type EngagementCardProperties = {
  type: EngagementCardType;
  title: string;
  description?: string;
  duration?: number;
  isModerator?: boolean;
  dates?: React.JSX.Element;
  honorarium?: number;
  points?: number;
  actionLabel?: string;
  action: () => void;
  icon: React.JSX.Element;
  iconBgColor: string;
  additionalActions?: React.JSX.Element;
  accentIcon?: React.JSX.Element;
};
export const EngagementCard = ({
  type,
  title,
  description,
  duration,
  isModerator = false,
  dates,
  honorarium,
  points,
  actionLabel,
  action,
  icon,
  iconBgColor,
  additionalActions,
  accentIcon,
}: EngagementCardProperties) => {
  const { t } = useTranslation();
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const styledIcon = React.cloneElement(icon, {
    sx: {
      color: palette.keyops.white.main,
      fontSize: isMobile ? '14px' : '27px',
    },
  });
  const styledAccentIcon = accentIcon
    ? React.cloneElement(accentIcon, {
        sx: {
          color: iconBgColor,
          fontSize: '24px',
        },
      })
    : undefined;

  const EngagementCardElement = styled(Card)(() => ({
    boxShadow: 'none',
    border: `1px solid ${palette.keyops.blue.lightSteelBlue20}`,
    borderRadius: '8px',
    width: '100%',
    ...hoverCardStyle(),
    '.callToAction': {
      fontSize: '14px',
      color: palette.keyops.blue.keyopsBlue,
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      fontWeight: 500,
    },
    '.MuiCardContent-root': {
      padding: isMobile ? '16px 16px 8px 16px' : '16px 24px 16px 24px',
      borderLeft: isMobile ? '8px solid ' + iconBgColor + '33' : undefined,
      '&:last-child': {
        paddingBottom: isMobile ? '8px' : '16px',
      },
      backgroundColor: palette.keyops.background.paper,
    },
    '.textWithIcons': {
      display: 'flex',
      alignItems: 'center',
    },
    '.MuiAvatar-root': {
      backgroundColor: iconBgColor,
      width: isMobile ? '24px' : '46px',
      height: isMobile ? '24px' : '46px',
    },
    '.honosAndPoints': {
      backgroundColor: isMobile
        ? undefined
        : palette.keyops.background.highlight,
      padding: isMobile ? '8px 8px 0px 8px' : '8px 16px 8px 16px',
      borderRadius: isMobile ? '0px' : '8px',
      color: palette.keyops.blue.keyopsBlue,
      textAlign: isMobile ? 'center' : undefined,
      height: isMobile ? undefined : '88px',
      display: isMobile ? undefined : 'flex',
      alignItems: 'center',
      borderTop: isMobile
        ? '1px solid ' + palette.keyops.blue.midnightBlue + '30'
        : undefined,
    },
  }));

  const typeBadgeStyle: React.CSSProperties = {
    width: '69px',
    backgroundColor: iconBgColor + '33',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const footerItems = [];
  if (duration && !isModerator) {
    footerItems.push(
      <>
        <ClockCircle
          sx={{
            color: palette.keyops.blue.midnightBlue,
            fontSize: '18px',
          }}
        />
        <span>
          {t('dashboard.activeEngagementsSection.minutes', { duration })}
        </span>
      </>,
    );
  }
  if (dates) {
    footerItems.push(
      <>
        <Calendar
          sx={{
            color: palette.keyops.blue.midnightBlue,
            fontSize: '18px',
          }}
        />
        <span>{dates}</span>
        {!isModerator && (
          <PrimaryTooltip
            placement="top"
            title={t('dashboard.activeEngagementsSection.datesTooltipText')}
          >
            <div style={{ display: 'flex' }}>
              <InfoOutline
                sx={{
                  fontSize: '18px',
                  color: palette.keyops.blue.lightSteelBlue70,
                }}
              />
            </div>
          </PrimaryTooltip>
        )}
      </>,
    );
  }

  return (
    <EngagementCardElement onClick={action}>
      <CardContent>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 2 : 3}
        >
          {/* Icon block */}
          {!isMobile && (
            <>
              <ModeratorBadge showBadge={isModerator}>
                <Box sx={typeBadgeStyle}>
                  <Avatar>{styledIcon}</Avatar>
                </Box>
              </ModeratorBadge>
            </>
          )}
          {/* Title, description and footer details */}
          <Stack spacing={0.5} flex={1}>
            <ModeratorBadge showBadge={isMobile && isModerator}>
              <Stack direction="row" className="textWithIcons" spacing={1}>
                {isMobile && <Avatar>{styledIcon}</Avatar>}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <KeyopsHeader3>{t('general.' + type)}</KeyopsHeader3>
                  {styledAccentIcon}
                </Box>
              </Stack>
            </ModeratorBadge>
            <Typography>{title}</Typography>
            {description && <KeyopsSubTitle1>{description}</KeyopsSubTitle1>}
            {footerItems.length > 0 && (
              <EngagementCardFooter items={footerItems} />
            )}
          </Stack>
          {/* Additional actions */}
          {additionalActions}
          {/* Honos, points and CTA */}
          <Box width={isMobile ? undefined : '163px'}>
            <Stack
              rowGap={1}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              height={'100%'}
            >
              {/* Honos and points */}
              {!!points || !!honorarium ? (
                <EngagementEarningsDisplay
                  honorarium={honorarium}
                  points={points}
                  renderMultiLine={!isMobile}
                  textAlign={isMobile ? 'center' : 'left'}
                  className="honosAndPoints"
                />
              ) : (
                <Box />
              )}
              {!isMobile && actionLabel && (
                <Box className="callToAction">
                  {actionLabel}{' '}
                  <RightArrow sx={{ marginLeft: '8px', fontSize: '18px' }} />
                </Box>
              )}
            </Stack>
          </Box>
        </Stack>
      </CardContent>
    </EngagementCardElement>
  );
};
