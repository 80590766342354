import React, { ReactNode } from 'react';
import {
  Badge,
  BadgeProps,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

interface ModeratorBadgeProperties {
  showBadge: boolean;
  children: ReactNode;
}

export const ModeratorBadge: React.FC<ModeratorBadgeProperties> = ({
  showBadge,
  children,
}) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      left: isMobile ? 'auto' : 14,
      right: isMobile ? 24 : 'auto',
      top: 10,
      borderRadius: 0,
      borderTopRightRadius: !isMobile && 6,
      borderBottomRightRadius: !isMobile && 6,
      borderTopLeftRadius: isMobile && 6,
      borderBottomLeftRadius: isMobile && 6,
      padding: '8px 8px',
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.keyops.purple.main,
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400,
    },
  }));
  return (
    <StyledBadge
      badgeContent={'Moderator'}
      anchorOrigin={{
        vertical: 'top',
        horizontal: isMobile ? 'right' : 'left',
      }}
      invisible={!showBadge}
    >
      {children}
    </StyledBadge>
  );
};
