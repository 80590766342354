// External Dependencies
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

// Internal Dependencies
import { InNetworkAdBoardInviteDto } from '@keyops-hcp/dtos';
import { EngagementCard } from './EngagementCard';
import {
  FilledMessage2,
  isEligibleForRewards,
  RightArrow,
} from '@keyops-hcp/ui-components';

const getFormattedDates = (
  language: string,
  startDateString?: string | Date,
  endDateString?: string | Date,
) => {
  if (!startDateString && !endDateString) {
    return { formattedStartDate: undefined, formattedEndDate: undefined };
  }
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  //only include year if start date and end date are different years
  const startDateYearFormat =
    startDate.getFullYear() === endDate.getFullYear() ? undefined : 'numeric';

  const formattedStartDate = startDate.toLocaleDateString(language, {
    month: 'short',
    day: 'numeric',
    year: startDateYearFormat,
  });
  const formattedEndDate = endDate.toLocaleDateString(language, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return { formattedStartDate, formattedEndDate };
};

export const ActiveAdBoardCard = ({
  adBoardInvite,
}: {
  adBoardInvite: InNetworkAdBoardInviteDto;
}) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const theme = useTheme();

  let duration = 0;
  for (const sectionGroup of adBoardInvite.adBoard.sectionGroups) {
    duration += sectionGroup.estimatedTime;
  }

  const { formattedStartDate, formattedEndDate } = getFormattedDates(
    i18n.language,
    adBoardInvite.adBoard.startDate,
    adBoardInvite.adBoard.endDate,
  );

  const getActionLabel = () => {
    switch (adBoardInvite.state) {
      case 'sent': {
        return t('dashboard.activeEngagementsSection.adBoard.cta.sent');
      }
      case 'confirmed':
      case 'contract-signed':
      case 'failed-contract-confirmation': {
        const key =
          adBoardInvite.adBoard.state === 'live'
            ? 'dashboard.activeEngagementsSection.adBoard.cta.live'
            : 'dashboard.activeEngagementsSection.adBoard.cta.planning';
        return t(key);
      }
      default: {
        return t('dashboard.activeEngagementsSection.adBoard.cta.learnMore');
      }
    }
  };

  return (
    <EngagementCard
      action={() => navigate('/adboard-landing/' + adBoardInvite.adBoardId)}
      actionLabel={getActionLabel()}
      honorarium={adBoardInvite.honorarium}
      title={adBoardInvite.adBoard.title}
      description={adBoardInvite.adBoard.company.name}
      icon={<FilledMessage2 />}
      iconBgColor={theme.palette.keyops.teal}
      type="adBoard"
      duration={duration}
      isModerator={adBoardInvite.role === 'moderator'}
      dates={
        formattedStartDate && formattedEndDate ? (
          <span className="textWithIcons">
            {formattedStartDate}
            <RightArrow
              sx={{
                px: '4px',
                fontSize: '17px',
                color: theme.palette.keyops.blue.lightSteelBlue,
              }}
            />
            {formattedEndDate}
          </span>
        ) : undefined
      }
      points={
        isEligibleForRewards(adBoardInvite?.invitee?.user?.hcpType)
          ? adBoardInvite.rewards
          : 0 // Points are hidden when set at 0
      }
    />
  );
};
